import React from 'react'
import ContentBlockCenter from '../components/ContentBlocks/ContentBlockCenter'
import { Box, Text, Heading } from 'theme-ui'
import PageHero from '../components/Heros/PageHero'
export default function about() {
  return (
    <Box sx={styles.container}>
      <PageHero imageID={'about-pagehero'} />
      <ContentBlockCenter storyId={'a-art-Kg6kMOBhT8_gTasOIDQNEw'} />
    </Box>
  )
}

const styles = {
  container: {
    paddingTop: '62px',
    display: 'flex',
    flexDirection: 'column',
  },
}
